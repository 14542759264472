import {useEffect, useState} from 'react'
import classnames from 'classnames'
import hotkeys from 'hotkeys-js'
import {wait} from '@/utils/utils'
import useApi from '@/api/useApi'

import Button from '@marpla/ui/components/Button'
import Card from '@marpla/ui/components/Card'
import {enqueueSnackbar} from '@marpla/ui/components/SnackbarProvider'
import SwitchLabel from '@marpla/ui/components/SwitchLabel'
import Typography from '@marpla/ui/components/Typography'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'

import CopyIcon from '@marpla/ui/icons/Copy'
import TickIcon from '@marpla/ui/icons/Tick'

import styles from './index.module.scss'

import {prodApiLSKey, visibilityLSKey} from './ls-keys'

export default function QATools() {
	const [isHidden, setIsHidden] = useState(
		import.meta.env.MODE === 'production'
	)
	const [collapseToolbox, setCollapseToolbox] = useState(
		window.localStorage.getItem(visibilityLSKey) === 'true'
	)
	const [isCopied, setIsCopied] = useState(false)
	useEffect(() => {
		window.localStorage.setItem(visibilityLSKey, String(collapseToolbox))
	}, [collapseToolbox])
	const [isProdApi, setIsProdApi] = useState(
		window.localStorage.getItem(prodApiLSKey) === 'true'
	)
	useEffect(() => {
		window.localStorage.setItem(prodApiLSKey, String(isProdApi))
	}, [isProdApi])
	const changeIsProdApi = (newValue: boolean) => {
		setIsProdApi(newValue)
		window.location.reload()
	}

	const {saveConfig} = useApi()
	const [isSavingConfig, seIsSavingConfig] = useState(false)
	const onShowAllColumns = async () => {
		if (!('qa_marpla_table_columns' in window)) {
			enqueueSnackbar('Таблицы не найдены или они еще не загрузились', {
				variant: 'error',
			})
			return
		}
		const columns = [...window.qa_marpla_table_columns]
		if (columns.length === 0) {
			enqueueSnackbar('Таблицы не найдены или они еще не загрузились', {
				variant: 'error',
			})
		}
		seIsSavingConfig(true)
		const items: Record<string, string> = {}
		columns.forEach(column => {
			items[column] = 'true'
		})
		await saveConfig({
			items,
		})
		seIsSavingConfig(false)
		enqueueSnackbar('Столбцы успешно сброшены. Перезагружаю страницу...', {
			variant: 'success',
		})
		await wait(2500)
		window.location.reload()
	}

	const onCopyData = () => {
		const token = window.localStorage.getItem('token')
		const data: Record<string, string | null> = {
			Токен: token,
			Место: window.location.pathname,
			'Войти под юзером': token
				? `${window.location.origin}${window.location.pathname}?prior_token=${token}`
				: null,
			'Войти на локалхосте': token
				? `http://localhost:5173${window.location.pathname}?prior_token=${token}`
				: null,
			'Включено прод API': isProdApi ? 'да' : 'нет',
		}
		let dataString = ``
		for (const key in data) {
			const value = data[key]
			if (value === null || value === undefined || value === '') continue
			dataString += `${key}: ${value}\n`
		}
		navigator.clipboard.writeText(dataString).then(
			() => {
				setIsCopied(true)
				setTimeout(() => {
					setIsCopied(false)
				}, 2000)
			},
			() => {
				enqueueSnackbar('Копирование из браузера не поддерживается системой', {
					variant: 'error',
					autoHideDuration: 2000,
				})
			}
		)
	}

	useEffect(() => {
		hotkeys('ctrl+shift+d, command+shift+d', () => {
			setIsHidden(prev => !prev)
		})
		return () => hotkeys.unbind('ctrl+shift+d, command+shift+d')
	}, [])

	if (isHidden) {
		return null
	}

	if (collapseToolbox) {
		return (
			<Card
				className={classnames(styles.root, styles.collapsed)}
				onClick={() => setCollapseToolbox(false)}
			>
				<ArrowOutwardIcon className={styles.open} />
			</Card>
		)
	}

	return (
		<Card className={styles.root}>
			<SwitchLabel
				label="Включить прод API"
				checked={isProdApi}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					changeIsProdApi(e.target.checked)
				}
			/>
			{isCopied ? (
				<Button variant="primary" size="sm" startIcon={<TickIcon />}>
					Скопировано
				</Button>
			) : (
				<Button
					variant="primary"
					size="sm"
					onClick={onCopyData}
					startIcon={<CopyIcon />}
				>
					Скопировать данные
				</Button>
			)}
			<Button
				variant="secondary"
				size="sm"
				onClick={onShowAllColumns}
				isLoading={isSavingConfig}
			>
				Вернуть известные столбцы
			</Button>
			<Typography variant="caption" shade={600}>
				CTRL+SHIFT+D чтобы скрыть окно
			</Typography>
			<ArrowOutwardIcon
				className={styles.close}
				onClick={() => setCollapseToolbox(true)}
			/>
		</Card>
	)
}
