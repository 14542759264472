import {createSlice} from '@reduxjs/toolkit'

export type User = {
	email: string | undefined
	phone: string | undefined
	registrationDate: string | undefined
	paidReferrals: number
	totalReferrals: number
	refferalUrl: string
	uniqueQueries: number
	projects: number
	phrases: number
	salesMonth: number
	balance: number
	tariffs: {
		phrase_count_min: number
		phrase_count_max: number
		value: number
		title: string
		price_per_day: number
		price: number
	}[]
	tariffFreeDays: number
	dayPrice: number
	bonusTime: {
		minutes: number
		hours: number
		days: number
		totalMinutes: number
		hasTime: boolean
	}
	isLoadingAccessSensitiveData: boolean
	isLoadingUserBasicData: boolean
	isLoadingFinanceData: boolean
}

export type Action<P> = {
	type: string
	payload: P
}

const initialState: User = {
	email: undefined,
	phone: undefined,
	registrationDate: undefined,
	paidReferrals: 0,
	totalReferrals: 0,
	refferalUrl: '',
	uniqueQueries: 0,
	projects: 0,
	phrases: 0,
	salesMonth: 0,
	balance: 0,
	tariffs: [],
	tariffFreeDays: 0,
	dayPrice: 0,
	bonusTime: {
		minutes: 0,
		hours: 0,
		days: 0,
		totalMinutes: 0,
		hasTime: false,
	},
	isLoadingAccessSensitiveData: true,
	isLoadingUserBasicData: true,
	isLoadingFinanceData: true,
}

export const userSlice = createSlice({
	name: 'userSlice',
	initialState,
	reducers: {
		setUser: (state, action: Action<Partial<User>>) => ({
			...state,
			...action.payload,
		}),
	},
})

export const {setUser} = userSlice.actions

export default userSlice.reducer
