import {useEffect, useMemo, useState} from 'react'
import useApi from '@/api/useApi'

import Notification, {NorificationProps} from '../Notification'

import {ApiResponse} from '@/types/types'

type NotificationResponse = {
	text: string
} & ApiResponse

export type NotificationBackendProps = Omit<NorificationProps, 'children'>

export default function NotificationBackend(props: NotificationBackendProps) {
	const {getNotifications} = useApi()
	const [notification, setNotification] = useState<string | null>(null)

	useEffect(() => {
		getNotifications()
			.then((response: NotificationResponse) => {
				if (!response || response.error) return
				setNotification(response.text)
			})
			.catch(() => {})
	}, [getNotifications])

	const result = useMemo(
		() =>
			notification?.replace(
				/(https?:\/\/\S+?)(?:\s|$)/g,
				'<a href="$1" rel="noopener noreferrer">$1</a> '
			),
		[notification]
	)

	return <Notification {...props} innerHtml={result} />
}
