export type VersionInfo = {
	version: string
}

const packageJsonVersion = window.version
const enabled = !import.meta.env.DEV
const interval =
	Number(import.meta.env.VITE_CHECK_VERSION_INTERVAL_SECONDS) * 1000
const navigationSupport = 'navigation' in window
let checkCount = -1

async function fetchVersion(): Promise<VersionInfo> {
	const response = await fetch(
		`${window.location.origin}/version.json?no-cache=${Date.now()}`,
		{
			cache: 'no-cache',
		}
	)
	return response.json()
}

function checkAndReloadIfNewVersion() {
	checkCount += 1
	fetchVersion()
		.then(versionInfo => {
			if (versionInfo.version !== packageJsonVersion) {
				if (navigationSupport && checkCount > 0) {
					window.navigation.addEventListener('navigate', () => {
						setTimeout(() => {
							window.location.reload()
						}, 500)
					})
				} else {
					window.location.reload()
				}
			} else {
				setTimeout(checkAndReloadIfNewVersion, interval)
			}
		})
		.catch(err => {
			console.error('Ошибка при проверке версии сборки:', err)
			setTimeout(checkAndReloadIfNewVersion, interval)
		})
}

if (enabled) checkAndReloadIfNewVersion()
