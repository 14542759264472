import type {PhraseRow} from '@/pages/report/utils/comparisons-data-processor'
import type {SeoList} from '@/pages/report/utils/report-table-data-processor'

import {createSlice} from '@reduxjs/toolkit'

export type SeoListExtended = SeoList & {
	foundPhrasesCount: number | null
	totalPhrasesCount: number | null
}

export type ComparsionsData = {
	checkedPhraseIds: PhraseRow['id'][]
	// checkedPhraseRows: PhraseRow[]
	listId: SeoList['id']
	topRadioFilter: string
	secondaryRadioFilter: string
	date: string | null
	lists: SeoListExtended[]
	checkedProductIds: string[]
	selectedProductIds: string[]
	allPhrasesCount: number
	tableType: string
}

const initialState: ComparsionsData = {
	checkedPhraseIds: [],
	// checkedPhraseRows: [],
	listId: 'all',
	topRadioFilter: 'all',
	secondaryRadioFilter: 'all',
	date: null,
	lists: [],
	checkedProductIds: [],
	selectedProductIds: [],
	allPhrasesCount: 0,
	tableType: 'phrases',
}

export type Action<P> = {
	type: string
	payload: P
}

export const comparisonsTabDataSlice = createSlice({
	name: 'phrasesLibraryDataSlice',
	initialState,
	reducers: {
		setCheckedPhraseIds: (state, action: Action<string[]>) => ({
			...state,
			checkedPhraseIds: action.payload,
		}),
		setListId: (state, action: Action<string>) => ({
			...state,
			listId: action.payload,
		}),
		setTopRadioFilter: (state, action: Action<string>) => ({
			...state,
			topRadioFilter: action.payload,
		}),
		setSecondaryRadioFilter: (state, action: Action<string>) => ({
			...state,
			secondaryRadioFilter: action.payload,
		}),
		setDate: (state, action: Action<string>) => ({
			...state,
			date: action.payload,
		}),
		setLists: (state, action: Action<SeoListExtended[]>) => ({
			...state,
			lists: action.payload,
		}),
		setCheckedProductIds: (state, action: Action<string[]>) => ({
			...state,
			checkedProductIds: action.payload,
		}),
		setSelectedProductIds: (state, action: Action<string[]>) => ({
			...state,
			selectedProductIds: action.payload,
		}),
		setAllPhrasesCount: (state, action: Action<number>) => ({
			...state,
			allPhrasesCount: action.payload,
		}),
		setTableType: (state, action: Action<string>) => ({
			...state,
			tableType: action.payload,
		}),
		reset: state => {
			const {checkedProductIds, selectedProductIds, date, tableType} = state
			return {
				...initialState,
				checkedProductIds,
				selectedProductIds,
				tableType,
				date,
			}
		},
	},
})

export const {
	setCheckedPhraseIds,
	setListId,
	setTopRadioFilter,
	setSecondaryRadioFilter,
	setLists,
	setAllPhrasesCount,
	setDate,
	setCheckedProductIds,
	setSelectedProductIds,
	setTableType,
	reset,
} = comparisonsTabDataSlice.actions

export default comparisonsTabDataSlice.reducer
