import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import CoinsIcon from '@marpla/ui/icons/Coins'
import GridIcon from '@marpla/ui/icons/Grid'
import MonitorIcon from '@marpla/ui/icons/Monitor'
import NewsIcon from '@marpla/ui/icons/News'
import UsersIcon from '@marpla/ui/icons/Users'
import WalletIcon from '@marpla/ui/icons/Wallet'

const navLinks = [
	{
		to: '/report/project',
		children: 'SEO',
		icon: GridIcon,
	},
	{
		to: '/bidmanager',
		children: 'Биддер',
		icon: MonitorIcon,
	},
	{
		to: '/abtests',
		children: 'A/B тесты',
		icon: ErrorOutlineIcon,
	},
	{
		to: '/sales',
		children: 'Продажи',
		icon: CoinsIcon,
	},
	{
		to: '/finance',
		children: 'Тарифы',
		icon: WalletIcon,
	},
	{
		to: '/partner',
		children: 'Партнерка',
		icon: UsersIcon,
	},
	{
		to: 'https://t.me/marpla_ru',
		children: 'Новости',
		icon: NewsIcon,
	},
]

export default navLinks
