import type {
	PhraseRow,
	SeoList,
} from '@/pages/report/utils/phrases-library-server-data-processor'

import {createSlice} from '@reduxjs/toolkit'

export type SeoListExtended = SeoList & {
	foundPhrasesCount: number | null
	totalPhrasesCount: number | null
}

export type ProjectPhrasesData = {
	checkedPhraseIds: PhraseRow['id'][]
	// checkedPhraseRows: PhraseRow[]
	listId: SeoList['id']
	placeRangeRadioFilter: string
	lists: SeoListExtended[]
}

const initialState: ProjectPhrasesData = {
	checkedPhraseIds: [],
	// checkedPhraseRows: [],
	listId: 'all',
	placeRangeRadioFilter: 'all',
	lists: [],
}

export type Action<P> = {
	type: string
	payload: P
}

export const phrasesLibraryDataSlice = createSlice({
	name: 'phrasesLibraryDataSlice',
	initialState,
	reducers: {
		setCheckedPhraseIds: (state, action: Action<string[]>) => ({
			...state,
			checkedPhraseIds: action.payload,
		}),
		setListId: (state, action: Action<string>) => ({
			...state,
			listId: action.payload,
		}),
		setPlaceRangeRadioFilter: (state, action: Action<string>) => ({
			...state,
			placeRangeRadioFilter: action.payload,
		}),
		setLists: (state, action: Action<SeoListExtended[]>) => ({
			...state,
			lists: action.payload,
		}),
		reset: () => ({...initialState}),
	},
})

export const {
	setCheckedPhraseIds,
	setListId,
	setPlaceRangeRadioFilter,
	setLists,
	reset,
} = phrasesLibraryDataSlice.actions

export default phrasesLibraryDataSlice.reducer
