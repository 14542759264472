import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import useURLParams from '@/hooks/useURLParams'

import {enqueueSnackbar} from '@marpla/ui/components/SnackbarProvider'

import {useApplyRedirectMutation} from '@/redux/api/auth-api'
import {isAuthorized, setToken} from '@/redux/api/token'

export default function useCommonAuth() {
	const [triggerApplyRedirect] = useApplyRedirectMutation()
	const {access_code: accessCode} = useURLParams() as {access_code: string}
	const navigate = useNavigate()
	const _isAuthorized = isAuthorized()

	useEffect(() => {
		if (!accessCode) {
			return
		}
		;(async () => {
			const response = await triggerApplyRedirect({accessCode})

			if ('error' in response || response.data.result === false) {
				if (_isAuthorized) {
					enqueueSnackbar(
						'Возникли проблемы при авторизации в SEO модуле. Используется ранее авторизованный аккаунт.',
						{
							variant: 'warning',
						}
					)
					navigate('/report/project', {replace: true})
				} else {
					enqueueSnackbar(
						'Не удалось авторизоваться в SEO модуле. Попробуйте авторизоваться через форму входа.',
						{
							variant: 'error',
						}
					)
					navigate('/signin', {replace: true})
				}
			} else {
				setToken(response.data.accessToken)
				window.location.replace(window.location.pathname)
			}
		})()
	}, [triggerApplyRedirect, accessCode, navigate, _isAuthorized])
}
