import {createSlice} from '@reduxjs/toolkit'

export type Action<P> = {
	type: string
	payload: P
}

export type ReportProjectData = {
	status: 'complete' | 'loading'
	id: string | null
	name: string | null
	articles: number | null
	phrases: number | null
}

const initialState: ReportProjectData = {
	status: 'loading',
	id: null,
	name: null,
	articles: null,
	phrases: null,
}

export const reportProjectSlice = createSlice({
	name: 'reportProjectSlice',
	initialState,
	reducers: {
		setProjectData: (state, action: Action<ReportProjectData>) => ({
			...state,
			...action.payload,
		}),
		reset: () => ({...initialState}),
	},
})

export const {setProjectData, reset} = reportProjectSlice.actions

export default reportProjectSlice.reducer
