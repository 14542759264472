import {format, formatISO, parseISO, sub} from 'date-fns'

import {ISODateString} from '@/types/types'

export const ISODateStringToddMM = (_ISODateString: ISODateString): string =>
	format(new Date(_ISODateString), 'dd.MM')

const ISODateStringToHmm = (_ISODateString: ISODateString): string =>
	format(new Date(_ISODateString), 'H:mm')

export const getDateHeaderName = (_ISODateString: ISODateString) => {
	if (/00:00:00/.test(_ISODateString)) {
		return ISODateStringToddMM(_ISODateString)
	}
	return ISODateStringToHmm(_ISODateString)
}

export const cutHours = (_ISODateString: ISODateString) =>
	_ISODateString.substring(0, 10)

export const isHistoryTime = (_ISODateString: ISODateString) =>
	!_ISODateString.includes('00:00:00')

export const formattedDateToYYMMDD = (_ISODateString: ISODateString) => {
	const date = parseISO(_ISODateString)
	const formattedDate = formatISO(date, {representation: 'date'})
	return formattedDate
}

export const isValidDateString = (str: string) =>
	String(new Date(str)) !== 'Invalid Date'

export const getUTCFromISOString = (dateStr: string) => {
	const date = new Date(dateStr)
	const utcDate = sub(date, {minutes: new Date().getTimezoneOffset()})
	return utcDate
}
