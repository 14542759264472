export const resetToken = () => {
	window.localStorage.removeItem('token')
	window.sessionStorage.removeItem('token')
}

export const setToken = (token: string, options?: {isSession?: boolean}) => {
	const _options = {
		isSession: false,
		...(options || {}),
	}
	window[_options.isSession ? 'sessionStorage' : 'localStorage'].setItem(
		'token',
		token
	)
	window[_options.isSession ? 'localStorage' : 'sessionStorage'].removeItem(
		'token'
	)
}

export const isAuthorized = () =>
	Boolean(
		window.localStorage.getItem('token') ||
			window.sessionStorage.getItem('token')
	)
