import type {
	PhraseRow,
	SeoList,
} from '@/pages/report/utils/report-table-data-processor'

import {createSlice} from '@reduxjs/toolkit'

import {ISODateString} from '@/types/types'

export type SeoListExtended = SeoList & {
	foundPhrasesCount: number
	totalPhrasesCount: number
}

export type ProjectPhrasesData = {
	checkedPhraseIds: PhraseRow['id'][]
	// checkedPhraseRows: PhraseRow[]
	listId: SeoList['id']
	mainRadioFilter: string
	topRadioFilter: string
	isBoostModeActive: boolean
	hideExtraColumns: boolean
	lists: SeoListExtended[]
	productCardChangeDays: ISODateString[]
}

const initialState: ProjectPhrasesData = {
	checkedPhraseIds: [],
	// checkedPhraseRows: [],
	listId: 'all',
	mainRadioFilter: 'all',
	topRadioFilter: 'all',
	isBoostModeActive: false,
	lists: [],
	productCardChangeDays: [],
	hideExtraColumns: false,
}

export type Action<P> = {
	type: string
	payload: P
}

export const projectPhrasesDataSlice = createSlice({
	name: 'projectPhrasesDataSlice',
	initialState,
	reducers: {
		setCheckedPhraseIds: (state, action: Action<string[]>) => ({
			...state,
			checkedPhraseIds: action.payload,
		}),
		setListId: (state, action: Action<string>) => ({
			...state,
			listId: action.payload,
		}),
		setMainRadioFilter: (state, action: Action<string>) => ({
			...state,
			mainRadioFilter: action.payload,
		}),
		setTopRadioFilter: (state, action: Action<string>) => ({
			...state,
			topRadioFilter: action.payload,
		}),
		setLists: (state, action: Action<SeoListExtended[]>) => ({
			...state,
			lists: action.payload,
		}),
		addList: (state, action: Action<SeoListExtended>) => ({
			...state,
			lists: state.lists.concat(action.payload),
		}),
		setProductCardChangeDays: (state, action: Action<ISODateString[]>) => ({
			...state,
			productCardChangeDays: action.payload,
		}),
		setIsBoostModeActive: (state, action: Action<boolean>) => ({
			...state,
			isBoostModeActive: action.payload,
		}),
		setHideExtraColumns: (state, action: Action<boolean>) => ({
			...state,
			hideExtraColumns: action.payload,
		}),
		reset: () => ({...initialState}),
	},
})

export const {
	setCheckedPhraseIds,
	setListId,
	setMainRadioFilter,
	setTopRadioFilter,
	setLists,
	addList,
	setProductCardChangeDays,
	setIsBoostModeActive,
	setHideExtraColumns,
	reset,
} = projectPhrasesDataSlice.actions

export default projectPhrasesDataSlice.reducer
