import type {PhraseRow} from '@/pages/report/utils/report-table-data-processor'
import {getProductCharacteristics} from '@/utils/utils'

import {createSlice} from '@reduxjs/toolkit'

export type ProductData = {
	name: string | null
	description: string | null
	characteristics: Array<{name: string; value: string}>
}

export type ChatGPTWord = {
	main: string
	variations: string[]
}

export type WordForms = Record<string, string[]>

export type GPTData = {
	status: 'loading' | 'complete'
	checkedPhraseIds: PhraseRow['id'][]
	productData: ProductData
	usageType: 'all' | 'used' | 'unused'
	wordsForChatGPT: ChatGPTWord[]
	gptMessagesLimit: number
	rawProductTextInfo: string | null
	wordForms: WordForms
}

const initialState: GPTData = {
	status: 'loading',
	checkedPhraseIds: [],
	productData: {
		name: null,
		description: null,
		characteristics: [],
	},
	usageType: 'all',
	wordsForChatGPT: [],
	gptMessagesLimit: 0,
	rawProductTextInfo: null,
	wordForms: {},
}

export type Action<P> = {
	type: string
	payload: P
}

export const gptSlice = createSlice({
	name: 'gptSlice',
	initialState,
	reducers: {
		setProductData: (state, action: Action<ProductData>) => ({
			...state,
			status: 'complete',
			productData: action.payload,
			rawProductTextInfo: `${action.payload.name} ${
				action.payload.description
			} ${getProductCharacteristics(action.payload.characteristics)
				.map(item => item.value)
				.join(' ')}`,
		}),
		setCheckedPhraseIds: (state, action: Action<string[]>) => ({
			...state,
			checkedPhraseIds: action.payload,
		}),
		setStatus: (state, action: Action<GPTData['status']>) => ({
			...state,
			status: action.payload,
		}),
		setUsageType: (state, action: Action<GPTData['usageType']>) => ({
			...state,
			usageType: action.payload,
		}),
		setWordsForChatGPT: (state, action: Action<ChatGPTWord[]>) => ({
			...state,
			wordsForChatGPT: action.payload,
		}),
		addWordsForChatGPT: (state, action: Action<ChatGPTWord[]>) => {
			const oldWordsSet = new Set(state.wordsForChatGPT.map(item => item.main))
			const newWordsForChatGPT = [...state.wordsForChatGPT]
			action.payload.forEach(chatGPTWord => {
				if (!oldWordsSet.has(chatGPTWord.main)) {
					newWordsForChatGPT.push(chatGPTWord)
				}
			})
			return {
				...state,
				wordsForChatGPT: newWordsForChatGPT,
			}
		},
		editWordForChatGPT: (
			state,
			action: Action<{
				word: string
				index: number
			}>
		) => {
			const _wordsForChatGPT = [...state.wordsForChatGPT]
			_wordsForChatGPT[action.payload.index] = {
				main: action.payload.word,
				variations: [action.payload.word],
			}
			return {
				...state,
				wordsForChatGPT: [..._wordsForChatGPT],
			}
		},
		removeWordsForChatGPT: (state, action: Action<ChatGPTWord[]>) => {
			const oldWordsMap = new Map()
			state.wordsForChatGPT.forEach(item => {
				oldWordsMap.set(item.main, item)
			})
			action.payload.forEach(word => {
				oldWordsMap.delete(word.main)
			})
			return {
				...state,
				wordsForChatGPT: [...oldWordsMap.values()],
			}
		},
		decreaseGPTMessagesLimit: state => ({
			...state,
			gptMessagesLimit: state.gptMessagesLimit - 1,
		}),
		setGPTMessagesLimit: (state, action: Action<number>) => ({
			...state,
			gptMessagesLimit: action.payload,
		}),
		setWordForms: (state, action: Action<WordForms>) => ({
			...state,
			wordForms: action.payload,
		}),
		reset: () => ({...initialState}),
	},
})

export const {
	setProductData,
	setCheckedPhraseIds,
	setUsageType,
	setStatus,
	setWordsForChatGPT,
	addWordsForChatGPT,
	editWordForChatGPT,
	removeWordsForChatGPT,
	decreaseGPTMessagesLimit,
	setGPTMessagesLimit,
	setWordForms,
	reset,
} = gptSlice.actions

export default gptSlice.reducer
