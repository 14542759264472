import {useCallback, useEffect, useMemo, useRef} from 'react'

import endpointsJSON from './endpoints.json'
import request from './request'

type EndpointFunction = (args?: Record<string, unknown>) => Promise<unknown>

type EndpointNames = {
	getReportArticleData: EndpointFunction
	getReportData: EndpointFunction
	getReportDataFast: EndpointFunction
	getReportCategories: EndpointFunction
	login: EndpointFunction
	getProjects: EndpointFunction
	getProjectArticles: EndpointFunction
	dispatchReportDataAction: EndpointFunction
	createList: EndpointFunction
	startParser: EndpointFunction
	getParserState: EndpointFunction
	activatePromocode: EndpointFunction
	getFinanceInfo: EndpointFunction
	getTransactionHistory: EndpointFunction
	getReferralsIndex: EndpointFunction
	createProject: EndpointFunction
	editProject: EndpointFunction
	findAdditionalPhrasesBySubject: EndpointFunction
	findAdditionalPhrasesBySubjectImproved: EndpointFunction
	findAdditionalPhrasesByArticles: EndpointFunction
	getDops: EndpointFunction
	duplicateProject: EndpointFunction
	deleteProject: EndpointFunction
	getUserData: EndpointFunction
	activateBM: EndpointFunction
	parsePhrase: EndpointFunction
	editList: EndpointFunction
	deleteList: EndpointFunction
	addArticleProject: EndpointFunction
	saveConfig: EndpointFunction
	getConfig: EndpointFunction
	signUp: EndpointFunction
	getCardAnalyse: EndpointFunction
	editCommonQueriesList: EndpointFunction
	deleteCommonQueriesList: EndpointFunction
	addCommonQueriesList: EndpointFunction
	dispatchPhrasesLibraryAction: EndpointFunction
	getCommonQueriesLists: EndpointFunction
	checkToken: EndpointFunction
	sendRefreshToken: EndpointFunction
	changePassword: EndpointFunction
	sendEmail: EndpointFunction
	getProjectInfo: EndpointFunction
	getReferralsFromDate: EndpointFunction
	getAccessSensitiveData: EndpointFunction
	getBMToken: EndpointFunction
	parsePriorityByPhrase: EndpointFunction
	getAlsoFound: EndpointFunction
	createPayment: EndpointFunction
	checkPayment: EndpointFunction
	parseCategory: EndpointFunction
	parseProjectCategories: EndpointFunction
	getPromotionTable: EndpointFunction
	getReportStatsHistory: EndpointFunction
	getComparableCards: EndpointFunction
	addCardsToCompare: EndpointFunction
	getPhrasesToCompare: EndpointFunction
	getCompareData: EndpointFunction
	deleteComparableCard: EndpointFunction
	getCompareDataWithBoosts: EndpointFunction
	parsePhraseArticles: EndpointFunction
	getNotifications: EndpointFunction
	getPhrasesFrequencyByMonth: EndpointFunction
	getMoscowDateTime: EndpointFunction
}

type EndpointParams = {
	name: string
	params: string[]
	method: 'post' | 'get' | 'patch' | 'put' | 'delete'
	url: string
	headers?: Record<string, string>
	baseURL?: string
}

type RequestParams = Omit<EndpointParams, 'name' | 'params'> & {
	baseURL?: string
	data: Record<string, unknown>
}

type APIHook = {
	token: string
	resetToken: () => void
	setToken: (data: {token: string; isSession: boolean}) => void
	isAuthorized: () => boolean
} & EndpointNames

const fillUrlParams = (url, params) => {
	for (const key in params) {
		url = url.replaceAll(`{${key}}`, params[key])
	}
	return url
}

export default function useApi(): APIHook {
	const token = useRef(
		window.localStorage.getItem('token') ||
			window.sessionStorage.getItem('token')
	)

	useEffect(() => {
		token.current &&
			request.interceptors.request.use(config => {
				config.headers.Authorization = `Bearer ${token.current}`
				return config
			})
	}, [])

	const endpoints = useMemo<Record<keyof EndpointNames, EndpointFunction>>(
		() =>
			endpointsJSON.reduce(
				(acc, endpoint: EndpointParams) => {
					const {name, params, method, url, headers = {}} = endpoint
					acc[name] = async (inputParams = {}, inputRequestParams = {}) => {
						try {
							const requestParams: RequestParams = {
								url: fillUrlParams(url, inputParams),
								method,
								data: {}, // without this, content-type header is not working, don't remove
								[method === 'get' ? 'params' : 'data']:
									params &&
									params.reduce((acc, param) => {
										acc[param] = inputParams[param]
										return acc
									}, {}),
								headers,
								...inputRequestParams,
							}
							if (url.charAt(0) !== '/') {
								const urlData = new URL(requestParams.url)
								requestParams.baseURL = urlData.origin
								requestParams.url = urlData.pathname
							}
							const {data} = await request(requestParams)

							return data
						} catch (err) {
							const responseData = err?.response?.data
							if (responseData && typeof responseData === 'object') {
								err.response.data.error = err.response.status
								return err.response.data
							}
							throw Error(err)
						}
					}
					return acc
				},
				{} as Record<keyof EndpointNames, EndpointFunction>
			),
		[]
	)

	const resetToken = useCallback(() => {
		window.localStorage.removeItem('token')
		window.sessionStorage.removeItem('token')
		token.current = null
		request.interceptors.request.use(config => {
			config.headers.Authorization = undefined
			return config
		})
	}, [])

	const setToken = useCallback(({token: tokenArg, isSession}) => {
		window[isSession ? 'sessionStorage' : 'localStorage'].setItem(
			'token',
			tokenArg
		)
		window[isSession ? 'localStorage' : 'sessionStorage'].removeItem('token')
		token.current = tokenArg
		request.interceptors.request.use(config => {
			config.headers.Authorization = `Token ${token.current}`
			return config
		})
	}, [])

	const isAuthorized = useCallback(
		() =>
			Boolean(
				window.localStorage.getItem('token') ||
					window.sessionStorage.getItem('token')
			),
		[]
	)

	return {
		...endpoints,
		token: token.current,
		resetToken,
		setToken,
		isAuthorized,
	}
}
