import {createSlice} from '@reduxjs/toolkit'

import type {ISODateString} from '../../types/types'

export type ReportProductData = {
	status: 'loading' | 'complete'
	brand: string
	img: string
	price: number
	title: string
	article: string
	brandUrl: string
	subjectId: number
	subjectName: string
	rating: number
	note: string
	description: string
	chart: Record<ISODateString, Record<string, number | null>>
}

export type SetProductDataAction = {
	type: string
	payload: ReportProductData
}

export type SetProductStatusAction = {
	type: string
	payload: ReportProductData['status']
}

const initialState: ReportProductData = {
	status: 'loading',
	brand: null,
	img: null,
	price: null,
	title: null,
	article: null,
	brandUrl: null,
	subjectId: null,
	subjectName: null,
	rating: null,
	note: null,
	description: null,
	chart: {},
}

export const reportProductSlice = createSlice({
	name: 'reportProductSlice',
	initialState,
	reducers: {
		setProductData: (state, action: SetProductDataAction) => ({
			...state,
			...action.payload,
		}),
		setProductStatus: (state, action: SetProductStatusAction) => ({
			...state,
			status: action.payload,
		}),
		reset: () => initialState,
	},
})

export const {setProductData, reset} = reportProductSlice.actions

export default reportProductSlice.reducer
