import ReactDOM from 'react-dom/client'
import {Provider as ReduxProvider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {enableMapSet} from 'immer'
import {disableDataGridLicenseError} from './utils/utils'
import {initSentry} from '@/utils/sentry'

import CssBaseline from '@mui/material/CssBaseline'
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles'
import SnackbarProvider from '@marpla/ui/components/SnackbarProvider'
import QATools from '@/components/QATools'
import App from '@/App'
import SentryErrorBoundary from '@/components/SentryErrorBoundary'

import './styles/global.scss'

import {store} from './redux/store'
import theme from './theme'

initSentry()
enableMapSet()
disableDataGridLicenseError()

ReactDOM.createRoot(document.getElementById('root')!).render(
	<SentryErrorBoundary>
		<ReduxProvider store={store}>
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<StyledEngineProvider injectFirst>
						<SnackbarProvider>
							<CssBaseline />
							<App />
							<QATools />
						</SnackbarProvider>
					</StyledEngineProvider>
				</BrowserRouter>
			</ThemeProvider>
		</ReduxProvider>
	</SentryErrorBoundary>
)
