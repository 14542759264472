import {rtkQueryApi} from './rtk-query-api'

export const userApi = rtkQueryApi
	.enhanceEndpoints({addTagTypes: ['Auth']})
	.injectEndpoints({
		endpoints: builder => ({
			createRedirect: builder.mutation<
				{
					result: boolean
					accessCode: string
				},
				{target: string}
			>({
				query: ({target}) => ({
					url: `create_redirect`,
					method: 'POST',
					body: {target},
				}),
			}),
			applyRedirect: builder.mutation<
				{
					result: boolean
					accessToken: string
				},
				{accessCode: string}
			>({
				query: ({accessCode}) => ({
					url: `apply_redirect`,
					method: 'POST',
					body: {accessCode},
				}),
			}),
		}),
	})

export const {useApplyRedirectMutation, useCreateRedirectMutation} = userApi
