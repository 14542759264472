export const PRODUCTION = import.meta.env.MODE === 'production'
export const DEV = import.meta.env.MODE !== 'production'
export const BASE_URL = import.meta.env.VITE_API_BASE_URL as string
export const PROJECT_NAME = import.meta.env.VITE_PROJECT_NAME as string
export const MODE = import.meta.env.MODE as string
export const BM_MODE = {
	development: 'dev',
	staging: 'stage',
	production: 'prod',
}[MODE]
export const MARPLA_BM_URL = import.meta.env.VITE_MARPLA_BM_URL as string
