import {useCallback, useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import classnames from 'classnames'
import {isValidDateString} from '@/pages/report/utils/date-helpers'
import useApi from '@/api/useApi'

import MarplaHeader, {
	getMarplaExtensionNavLink,
} from '@marpla/ui/components/Header'
import {BalanceProps} from '@marpla/ui/components/Header/Balance'
import IconButton from '@marpla/ui/components/IconButton'
import {enqueueSnackbar} from '@marpla/ui/components/SnackbarProvider'

import LogOutIcon from '@marpla/ui/icons/LogOut'

import {
	useLazyGetBmAccessTokenQuery,
	useLazyGetMoscowDateTimeQuery,
} from '@/redux/api/other-api'
import {RootState} from '@/redux/store'

import styles from './index.module.scss'

import navLinks from './navlinks'

function UserMenuContent() {
	const user = useSelector((state: RootState) => state.user)

	const {resetToken} = useApi()
	const onLogOut = useCallback(() => {
		if (window.confirm('Выйти из аккаунта?')) {
			resetToken()
			window.location.href = '/signin'
		}
	}, [resetToken])

	const onCopyEmail = useCallback(() => {
		navigator.clipboard.writeText(String(user.email)).then(
			() => {
				enqueueSnackbar('Email скопирован', {
					variant: 'success',
					autoHideDuration: 2000,
				})
			},
			() => {
				enqueueSnackbar('Копирование из браузера не поддерживается системой', {
					variant: 'error',
					autoHideDuration: 2000,
				})
			}
		)
	}, [user.email])

	return (
		<div className={classnames(styles.navLink, styles.disableHover)}>
			<span onClick={onCopyEmail} className={styles.email}>
				{user.email || 'Аккаунт'}
			</span>
			<IconButton variant="secondary" size="md" sx={{ml: 1}} onClick={onLogOut}>
				<LogOutIcon />
			</IconButton>
		</div>
	)
}

export default function Header() {
	const [triggerGetMoscowDateTimeMutation] = useLazyGetMoscowDateTimeQuery()
	const user = useSelector((state: RootState) => state.user)
	const [triggerGetBmAccessToken] = useLazyGetBmAccessTokenQuery()

	const [bmToken, setBmToken] = useState<string | null>(null)
	const marplaExtensionNavLink = useMemo(
		() => getMarplaExtensionNavLink(bmToken),
		[bmToken]
	)
	const navLinksWithMarplaExtension = useMemo(
		() => [
			...navLinks.slice(0, 3),
			marplaExtensionNavLink,
			...navLinks.slice(3),
		],
		[marplaExtensionNavLink]
	)

	const getMoscowDate = useCallback(async () => {
		const response = await triggerGetMoscowDateTimeMutation({})
		if ('error' in response) {
			return new Date()
		}
		if (isValidDateString(response.data.datetime)) {
			const serverDate = new Date(response.data.datetime)
			return serverDate
		} else {
			return new Date()
		}
	}, [triggerGetMoscowDateTimeMutation])

	useEffect(() => {
		triggerGetBmAccessToken({}).then(response => {
			if ('data' in response) {
				setBmToken(response.data.accessToken)
			}
		})
	}, [triggerGetBmAccessToken])

	const balanceProps: BalanceProps = {
		bonusTime: user.bonusTime.hasTime ? user.bonusTime : undefined,
		linkTo: '/finance',
		balance: user.balance,
	}

	return (
		<MarplaHeader
			navLinks={navLinksWithMarplaExtension}
			userMenuContent={<UserMenuContent />}
			getMoscowDate={getMoscowDate}
			balanceProps={user.isLoadingFinanceData ? undefined : balanceProps}
		/>
	)
}
